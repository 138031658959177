/*
  Project: Dicechess
  Author: Web Sabai Co., LTD
 */

// import '../../node_modules/foundation-sites/dist/js/foundation';
import core from './modules/core';

// window.jQuery = require('jquery');
// window.$ = require('jquery');

core();
