// import $ from 'jquery';
// Reason: more readable with brackets

/* eslint-disable */
// prettier-ignore
const core = () => {
    let ignore_onbeforeunload = false;
    $('a[href^=mailto], a[href^=tel]').on('click', function () {
        ignore_onbeforeunload = true;
    });

    $(window).bind('beforeunload', function () {
        if (ignore_onbeforeunload == false) {
            $('.preloader .contains').addClass('hide');
            $('.preloader').fadeIn(500);
        } else {
            ignore_onbeforeunload = false;
        }

    });

    $(document).ready(() => {
        $('a[href*="#"]')
                // Remove links that don't actually link to anything
                .not('[href="#"]')
                .not('[href="#0"]')
                .click(function (event) {
                    // On-page links
                    if (
                            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                            &&
                            location.hostname == this.hostname
                            ) {
                        // Figure out element to scroll to
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        // Does a scroll target exist?
                        if (target.length) {
                            // Only prevent default if animation is actually gonna happen
                            event.preventDefault();
                            $('html, body').animate({
                                scrollTop: target.offset().top
                            }, 500, function () {
                                // Callback after animation
                                // Must change focus!
                                var $target = $(target);
                                $target.focus();
                                if ($target.is(":focus")) { // Checking if the target was focused
                                    return false;
                                } else {
                                    $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                    $target.focus(); // Set focus again
                                }
                                ;
                            });
                        }
                    }
                });


        $('.tournament').on('click', function () {
            if ($(this).data("tournament")) {
                $('.regulation').addClass('hide');
                $('.tournament-' + $(this).data('tournament')).removeClass('hide');
                $('.popup').removeClass('hide');
                $('.popup-bg').removeClass('hide');
            }
        });
        $('.single-tournament').on('click', function () {
            $('.regulation').removeClass('hide');
            $('.popup').removeClass('hide');
            $('.popup-bg').removeClass('hide');
        });

        $(document).click(function (event) {
            var $target = $(event.target);
            if (!$target.closest('.dc-regulation-content').length && $('.dc-regulation-content').is(":visible") && !$target.closest('.dc-events').length && !$target.closest('.single-tournament').length) {
                $('.popup').addClass('hide');
                $('.popup-bg').addClass('hide');
                $(".regulation").addClass('hide');
            }
        });

        $('.tab').on('click', function () {
            $('.tab').removeClass('active');
            $(this).addClass('active');
            if ($(this).is(':first-child')) {
                $('article').removeClass('hide-for-small-only');
                $('.dc-events').addClass('hide-for-small-only');
            } else {
                $('article').addClass('hide-for-small-only');
                $('.dc-events').removeClass('hide-for-small-only');
            }
        });

        var controller = new ScrollMagic.Controller();
        // build scene
        var scene = new ScrollMagic.Scene({triggerElement: ".dc-events", triggerHook: 'onLeave'})
                .setPin('.dc-events')
                .offset(-30)
                .duration($(".core").height() - $(".dc-events").height() - 70);
        if ($(window).width() > 660) {
            scene.addTo(controller);
        } else {
            scene.destroy(true);
        }
        $(window).on('resize', function () {
            if ($(window).width() < 660) {
                scene.destroy(true);
            }
        });

        function setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }
        function getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        if (getCookie("eventsinfo")) {
            $('.dc-events-title .moreinfo').addClass('viewed');
        }

        $('.dc-more-circle .questionmark').on('click', function () {
            $('.dc-events-title .moreinfo').removeClass('viewed').addClass('view');
        });

        $('.dc-events-title .button').on('click', function () {
            setCookie('eventsinfo', 'true', '14');
            $('.dc-events-title .moreinfo').addClass('viewed').removeClass('view');
        });

        if ($('.single article').length) {
            let cvalue = getCookie("seen");
            if (cvalue == '') {
                setCookie("seen", $('article').attr('id'));
            } else if (!cvalue.includes($('article').attr('id')) && cvalue !== '') {
                let newCookie = cvalue + '.' + $('article').attr('id');
                console.log(newCookie);
                setCookie("seen", newCookie);
            }
        }
        if ($('.dc-latest-news').length) {
            let arr = getCookie("seen").split('.');
            $.each(arr, function (index, value) {
                $('.dc-latest-news[data-id="' + value + '"]').css({"opacity": "0.5", "border": "none"});
            })
        }

        if (getCookie("gradient") == null) {
            var seconds = 1;
        } else {
            var seconds = getCookie("gradient")
        }

        setInterval(function () {
            if (seconds > 59) {
                seconds = 1
            } else {
                seconds++
            }
            setCookie('gradient', seconds, '1')
        }, 1000);


        $('.background-gradient').css("animation-delay", "-" + seconds + "s");
        $('.calendar-row').first().css("animation-delay", "-" + seconds + "s");
        $('.preloader').delay(300).fadeOut(500);
        $('header,main').removeClass('hide');

        $(".calendar").datepicker({
            beforeShowDay: function (dt) {
                var Highlight = SelectedTournaments[dt];
                if (Highlight) {
                    var now = new Date();
                    if (dt.getTime() > now.getTime())
                        return [true, "highlighted-open", dt.toString()];
                    else
                        return [true, "highlighted-over", dt.toString()];
                } else {
                    return [true, '', ''];
                }
            },
            onSelect: function (dt, obj) {
                var d = new Date(dt);
                if (typeof SelectedTournaments[d] !== 'undefined') {
                    $('.regulation').addClass('hide');
                    $('.tournament-' + SelectedTournaments[d]).removeClass('hide');
                    $('.popup').removeClass('hide');
                    $('.popup-bg').removeClass('hide');
                }
            },
        });
        // Если юзер меняет язык сайта, прописываем это в куку
        $('.languages>ul>li>a').on('click', function () {
            setCookie('current_lang', $(this).text(), 90);
        });
    });
};
export default core;
